
import folkus_logo from '../../assets/img/folkus_logo.png';
import logo from '../../assets/img/logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
// import ls from 'local-storage';

const Header = (props) => {
    const navigate = useNavigate();
    // console.log("userData",userData);
    const location = useLocation();
    // const sideBarOpen = ls.length("headerHide");

    return (
        <>
            <nav id="sidebar" className={!props.sideBarOpen ? '' : 'active'} >
                <div onClick={() => navigate('/dashboard')} className="sidebar-header">
                    <img src={folkus_logo} className="img-fluid" />
                    <img src={logo} className="img-simpl" />
                </div>
                <ul className="list-unstyled components">
                    <li className={location.pathname === '/dashboard' ? 'active' : ''} >
                        <a onClick={() => navigate('/dashboard')} className="dashboard"><i className="fa-solid fa-gauge"></i><span>Dashboard</span></a>
                    </li>
                    <li className={location.pathname === '/language' ? 'active' : ''} >
                        <a onClick={() => navigate('/language')}><i className="fa-solid fa-globe" ></i><span>Languages</span></a>
                    </li>
                    <li className={location.pathname === '/admin' ? 'active' : ''} >
                        <a onClick={() => navigate('/admin')}><i className="fa-solid fa-user-gear"></i><span>Admin</span></a>
                    </li>
                    <li className={location.pathname === '/folkususers' ? 'active' : ''} >
                        <a onClick={() => navigate('/folkususers')}><i className="fa-solid fa-users"></i><span>Flokus User</span></a>
                    </li>
                    <li className={location.pathname === '/moments' ? 'active' : ''} >
                        <a onClick={() => navigate('/moments')}><i className="fa-solid fa-angry"></i><span>Moments</span></a>
                    </li>
                    <li className={location.pathname === '/stories' ? 'active' : ''} >
                        <a onClick={() => navigate('/stories')}><i className="fa-solid fa-circle-play"></i><span>Story</span></a>
                    </li>
                    <li className={location.pathname === '/pushnotification' ? 'active' : ''} >
                        <a onClick={() => navigate('/pushnotification')}><i className="fa-solid fa-toggle-on"></i><span>Push Notification</span></a>
                    </li>
                    <li className={location.pathname === '/suggesteduser' ? 'active' : ''} >
                        <a onClick={() => navigate('/suggesteduser')}><i className="fa-solid fa fa-chalkboard-user"></i><span>Suggested User</span></a>
                    </li>
                    <li className={location.pathname === '/categories' ? 'active' : ''} >
                        <a onClick={() => navigate('/categories')}><i className="fa-solid fa-tags"></i><span>Categories </span></a>
                    </li>
                    <li className={location.pathname === '/subscription' ? 'active' : ''} >
                        <a onClick={() => navigate('/subscription')}><i className="fa-solid fa-indian-rupee-sign"></i><span>Subscription</span></a>
                    </li>
                    <li className={location.pathname === '/usersubscription' ? 'active' : ''} >
                        <a onClick={() => navigate('/usersubscription')}><i className="fa-solid fa-user-tag"></i><span>User Subscription</span></a>
                    </li>
                    <li className={location.pathname === '/payment' ? 'active' : ''} >
                        <a onClick={() => navigate('/payment')}><i className="fa-solid fa-indian-rupee-sign"></i><span>Payment</span></a>
                    </li>
                    <li className={location.pathname === '/usernameclaimrequest' ? 'active' : ''} >
                        <a onClick={() => navigate('/usernameclaimrequest')}><i className="fa-solid fa-user-group"></i><span>Username claim request</span></a>
                    </li>
                    <li className={location.pathname === '/deactivatedreason' ? 'active' : ''} >
                        <a onClick={() => navigate('/deactivatedreason')}><i className="fa-solid fa-ban"></i><span>deactivated reason</span></a>
                    </li>
                    <li className={location.pathname === '/deactivatedaccounts' ? 'active' : ''} >
                        <a onClick={() => navigate('/deactivatedaccounts')}><i className="fa-solid fa-user-minus"></i><span>Deactivated accounts </span></a>
                    </li>
                    
                    <li className={location.pathname === '/reportedprofile' ? 'active' : ''} >
                        <a onClick={() => navigate('/reportedprofile')}><i className="fa-solid fa-user-alt-slash"></i><span>Reported Profile</span></a>
                    </li>
                    
                    <li className={location.pathname === '/reportedcomment' ? 'active' : ''} >
                        <a onClick={() => navigate('/reportedcomment')}><i className="fa-solid fa-comment-slash"></i><span>Reported Comment</span></a>
                    </li>
                    
                    <li className={location.pathname === '/reportedpost' ? 'active' : ''} >
                        <a onClick={() => navigate('/reportedpost')}><i className="fa-solid fa-slack-hash"></i><span>Reported Post</span></a>
                    </li>
                    <li className={location.pathname === '/feedback' ? 'active' : ''} >
                        <a onClick={() => navigate('/feedback')}><i className="fa-solid fa-ban"></i><span>Feedback</span></a>
                    </li>
                </ul>
            </nav>
        </>
    );
};
export default Header;